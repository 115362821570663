<template>
  <div class="comment-list">
    <error :errors="authorError" />
    <author-poster-form />
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import Error from "../../../partials/widgets/Error";
  import AuthorPosterForm from "../../../components/poster/AuthorPosterForm";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import { mapGetters } from 'vuex';
  export default {
    name: "index",
    mixins: [ formBusyMixin ],
    components: { Error, AuthorPosterForm },
    computed: {
      ...mapGetters(["authorError"])
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "ئاپتور باشقۇرۇش", route: "/authors/list" },
        { title: " ئاپتور تەشۋىقات رەسىم تەڭشىكى" },
      ]);
    },
    data(){
      return {};
    },
    methods: {}
  }
</script>
<style>
</style>
