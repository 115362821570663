import ApiService from "../common/api.service";
import { SET_AUTHOR_ERROR } from "../store/types";
import handleReject from "../common/handleReject";

export default {
  methods: {
    setAuthorPoster(formData, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.put(`/manager/authors/poster-setting`, formData)
        .then((response)=>{
          this.$store.commit(SET_AUTHOR_ERROR, null);
          resolve(response);
          busy && this.hideBusy();
        })
        .catch(response=>{
          handleReject(this.$store, response, SET_AUTHOR_ERROR);
          reject(response);
          busy && this.hideBusy();
        })
      })
    },
    getAuthorPoster(busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(`/manager/authors/poster-setting`)
        .then((response)=>{
          this.$store.commit(SET_AUTHOR_ERROR, null);
          resolve(response);
          busy && this.hideBusy();
        })
        .catch(response=>{
          handleReject(this.$store, response, SET_AUTHOR_ERROR);
          reject(response);
          busy && this.hideBusy();
        })
      })
    },
  }
}
